<template>
  <div id="login" class="container py-5 my-5">
    <pop-down class="" />

    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="60"
      :width="80"
      :transition="transition"
      loader="dots"
    ></loading>

    <div class="row mb-5">
      <!-- login form -->
      <div
        class="col-md-12 col-lg-6 col-sm-12 col-12 border-right login-account"
      >
        <h4 class="color-font text-center pb-5">{{ $t("LogIn") }}</h4>

        <form class="px-5 mx-3">
          <!-- Error message -->
          <div v-show="wrongIdentifier" class="my-n2">
            <p class="text-center mb-7 mt-n4 body-2 red--text">
              {{ errorMsg }}
            </p>
            <a
              @click.stop="
                recoverPassword = true;
                showLogin = false;
              "
              class="d-flex justify-center font-weight-bold body-2 red--text"
              style="text-decoration: underline"
            >
              <a
                href="/customer/account/forgotPassword"
                class="mb-2 color-font"
                style="margin-top: -8px"
                >{{ $t("ForgotPassword") }}</a
              >
            </a>
          </div>

          <!-- Email or Username -->
          <div class="form-group">
            <label for="accountEmail">{{ $t("E-mailorUsername") }}</label>
            <input
              type="usernameEmail"
              :class="[
                'form-control',
                'border-0',
                'py-5',
                $v.form.accountUsernameEmail.$invalid ? 'is-invalid' : '',
              ]"
              v-model="$v.form.accountUsernameEmail.$model"
              name="accountUsernameEmail"
              id="accountUsernameEmail"
              placeholder="Email or Username"
            />

            <div class="invalid-feedback" v-if="$v.form.$anyDirty">
              <span v-if="!$v.form.accountUsernameEmail.email">{{
                $t("InvalidEmailorUsername")
              }}</span
              ><br />

              <span v-if="!$v.form.accountUsernameEmail.required">{{
                $t("EmailorUsernameisrequired")
              }}</span>
            </div>
          </div>

          <!-- Password -->
          <div class="form-group">
            <label for="accountPassword">{{ $t("password") }}</label>
            <input
              :type="passwordFieldType"
              :class="[
                'form-control',
                'border-0',
                'py-5',
                $v.form.accountPassword.$invalid ? 'is-invalid' : '',
              ]"
              v-model="$v.form.accountPassword.$model"
              name="accountPassword"
              id="accountPassword"
              placeholder="xxxxxxxxx"
            />
            <span class="iconspan">
              <button
                class="p-0 btn btn-default"
                @click.prevent="switchVisibility"
              >
                <i class="fas fa-eye-slash"></i>
              </button>
            </span>

            <div class="invalid-feedback" v-if="$v.form.$anyDirty">
              <span v-if="!$v.form.accountPassword.required">{{
                $t("Passwordrequired")
              }}</span>
              <span v-if="!$v.form.accountPassword.minLength">{{
                $t("passwordAtLeast6")
              }}</span>
            </div>
          </div>

          <!-- Remember Me -->
          <div class="py-3 d-flex justify-content-between">
            <div
              class="form-check form-check-inline d-flex align-items-baseline"
            >
              <input
                type="checkbox"
                name="remember"
                v-model="form.stayLoggedIn"
                id="remember"
                class="form-check-input"
              />
              <label for="remember" class="form-check-label">{{
                $t("rememberMe")
              }}</label>
            </div>

            <div>
              <a
                href="/customer/account/forgotPassword"
                class="color-font font-weight-bold"
                >{{ $t("ForgotPassword") }}</a
              >
            </div>
          </div>

          <!-- Submit form -->
          <button
            class="
              mt-5
              btn btn-lg
              shadow
              btn-block btn-orange
              rounded-0
              d-flex
              justify-content-left
              align-items-baseline
              primo-btn
            "
            type="submit"
            @click.prevent="submitForm"
            @keyup.enter="submitForm"
          >
            <span class="">
              <i class="fas fa-lock"></i>
            </span>
            <span class="font-15 mx-auto pr-4">{{ $t("LogIn") }}</span>
          </button>

          <!-- Social Media Login Buttons -->
          <social-buttons>
            <template v-slot:google> {{ $t("LoginwithGoogle") }} </template>
            <template v-slot:facebook> {{ $t("LoginwithFacebook") }} </template>
          </social-buttons>
        </form>
      </div>

      <!-- Create Account -->
      <div class="col-lg-6 create-account">
        <h4 class="color-font text-center pb-0">{{ $t("CreateAccount") }}</h4>

        <div class="row mt-5">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <p class="text-center font-15">
                  <i class="fas fa-smile"></i>{{ $t("Happymsg") }}
                </p>

                <p class="font-15">
                  {{ $t("Firstmsg") }}
                </p>
              </div>

              <div class="col-md-12 btn-stack">
                <button
                  class="
                    mt-8
                    btn btn-lg
                    shadow
                    btn-block btn-orange
                    rounded-0
                    d-flex
                    justify-content-left
                    align-items-baseline
                    primo-btn
                  "
                  @click.prevent="$router.push({ name: 'signup' })"
                >
                  <span class="font-16">
                    <i class="fas fa-envelope"></i>
                  </span>
                  <span class="font-15 mx-auto pr-4">{{ $t("register") }}</span>
                </button>

                <!-- Social Media Login Buttons -->
                <social-buttons>
                  <template v-slot:google>
                    {{ $t("SignUpwithGoogle") }}
                  </template>
                  <template v-slot:facebook>
                    {{ $t("SignUpwithFacebook") }}
                  </template>
                </social-buttons>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  helpers,
  or,
} from "vuelidate/lib/validators";
import "@/assets/css/customer/signin/signin.css";
import PopDown from "@/components/widgets/PopDown";

import { mapGetters, mapActions, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import SocialButtons from "@/components/widgets/SocialButtons.vue";

const valid_username = helpers.regex("username", /^[a-zA-Z0-9_\.]+$/);

export default {
  components: {
    Loading,
    SocialButtons,
    PopDown,
  },
  data() {
    return {
      passwordFieldType: "password",
      phone: false,
      transition: "fade",

      bindProps: {
        mode: "international",
        dynamicPlaceholder: true,
        required: true,
        enabledCountryCode: true,
        enabledFlags: true,
        autocomplete: "off",
        name: "telephone",
        maxLen: 25,
        inputOptions: {
          showDialCode: true,
        },
        validCharactersOnly: true,
        wrapperClasses: ["form-group"],
        inputClasses: [
          "form-control",
          !this.validPhone ? "is-invalid" : "is-valid",
        ],
      },

      form: {
        accountEmail: "",
        accountUsernameEmail: "",
        accountPassword: "",
        accountPhone: "",
        stayLoggedIn: false,
        country: "Cameroon",
        countryCode: "CM",
      },
      validPhone: false,
      wrongIdentifier: false,
      loading: false,
      errorMsg: "Incorrect Account or Password Identifier !",
    };
  },

  validations: {
    form: {
      accountUsernameEmail: {
        required,
        valid: or(valid_username, email),
      },
      accountPassword: {
        required,
        minLength: minLength(6),
      },
    },
  },

  methods: {
    ...mapActions(["login", "getUser", "getOrders", "getStatesAction"]),

    ...mapMutations({
      setRegPhone: "SET_REGPHONE",
      setNoCartItems: "setNoCartItems",
      onLoginSuccess: "loginSuccess",
      setToken: "SET_TOKEN",
    }),

    countryChanged(country) {
      this.form.country = country;
      this.form.countryCode = country.iso2;
    },

    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },

    formLogin() {
      let payload = {
        identifier: this.form.accountUsernameEmail,
        password: this.form.accountPassword,
        remember_me: this.form.stayLoggedIn ? 1 : 0,
      };
      console.log("login payload: ", payload);
      this.login(payload).then((response) => {
        console.log("sign in response ", response);

        if (response.code === "USER_LOGIN") {
          console.log("login success 200: ", response);
          this.loading = false;

          this.wrongIdentifier = false;

          this.onLoginSuccess(response.data);

          this.setToken(response.data.access_token);

          this.getOrders().then(() => {
            this.setNoCartItems();
          });
        } else if (response.status === 500) {
          console.log("login error 500: ", response);
          this.wrongIdentifier = true;
          this.loading = false;
          this.errorMsg = this.$t("unactivatedAccount");
        } else {
          console.log("login error code: ", response);
          this.wrongIdentifier = true;
          this.loading = false;
          this.errorMsg = response.data.message;
        }
      });
    },

    submitForm() {
      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        console.log("form valid");
        this.loading = true;

        console.log("form data: ", {
          identifier: this.form.accountUsernameEmail,
          password: this.form.accountPassword,
          remember_me: this.form.stayLoggedIn,
        });
        // }
        this.formLogin();
      }
    },
  },

  computed: {
    ...mapGetters([
      "authenticating",
      "EndPointError",
      "EndPointErrorCode",
      "authenticationSuccess",
      "loggedIn",
    ]),

    regPhone: {
      get() {
        return this.$store.state.registerWithPhone;
      },
      set(val) {
        this.$store.commit("SET_REGPHONE", val);
      },
    },

    regWP() {
      return this.phone;
    },
  },

  created() {},

  watch: {},
};
</script>
